<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
      color="danger"
      >{{ message }}
    </vs-alert>
    <br />
    <div class="input-wrap">
      <vs-input
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        name="email"
        label-placeholder="Email"
        v-model="email"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("email") }}</span>
    </div>

    <div class="input-wrap">
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        type="password"
        name="password"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    </div>

    <div class="flex flex-wrap justify-between mb-3 input-wrap mt-10">
      <!--<vs-button type="border" @click="registerUser">Register</vs-button>-->
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
    <div class="flex flex-wrap justify-content-center my-5 input-wrap">
      <!--<vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>-->
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoginFirebaseVue from "./LoginFirebase.vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
      showDismissibleAlert: false,
      message: ""
    };
  },
  props: {},
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  },
  methods: {
    ...mapActions("child", ["getChildListByIds"]),
    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      if (!this.checkLogin()) return;

      // Loading
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        route: this.$route.name,
        userDetails: {
          email: this.email,
          password: this.password
        }
      };
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(async response => {
          const user = this.$store.state.AppActiveUser;
          let currentRole = user.userType;
          this.$vs.loading.close();

          if (currentRole == "learningCenter") {
            currentRole = user.userRole;
          }

          if (currentRole === "parent" || currentRole === "guardians") {
            let data = {
              childIds: user.children,
              parentId: user._id
            };
            await this.getChildListByIds(data);
          }
          this.$acl.change(currentRole);

          switch (currentRole) {
            case "superAdmin":
            case "admin":
              this.$router.push({ path: "/admin/dashboard" });
              break;
            case "centreDirector":
              if (
                this.$route.query.redirect &&
                this.$route.query.redirect != ""
              ) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ path: "/director/dashboard" });
              }
              break;
            case "centerAdmin":
              this.$router.push({ path: "/center-admin/dashboard" });
              break;
            case "parent":
            case "guardians":
              this.$router.push({ path: "/parent/dashboard" });
              break;
            case "teacher":
              this.$router.push({ path: "/teacher/dashboard" });
              break;
            default:
              this.$router.push({ path: "/" });
              break;
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.showDismissibleAlert = true;
          this.message = error.message;
          if (
            ("undefined" != typeof error.response &&
              error.response.status === 422) ||
            error.response.status === 403
          ) {
            this.message = error.response.data.message;
          }
        });
    }
  }
};
</script>
<style lang="scss">
.vs-con-input-label.is-label-placeholder {
  margin-top: 30px !important;
}

.input-wrap {
  padding: 0 30px;
  @media (max-width: 576px) {
    padding: 0;
  }
}

.login-b {
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
</style>
